<template lang="pug">
.main-wrapper.operadora-salvar

        Dialog(header='Remover agenda' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover a taxa?
            .ta-center.my-4
                Button.p-button-danger(label='Remover' @click='excluir(dialogApagar_data.index)')

        Dialog.dialogAdicionar(header='Adicionar Taxa' :modal='true' :visible.sync='dialogAdicionar' @hide='restaurarTaxasList(); clearTaxaModel()')
            form
                .p-grid.p-fluid.p-align-end
                    
                    .p-col-12.p-md-5(:class="{ 'form-group--error': !isValidFormPag }" style="margin-bottom:15px")
                        label.form-label Formas de Pagamento:
                        Dropdown(
                            v-model='taxaModel.cd_forma_pagamento'
                            :options='filtersOptions.formasPagamento'
                            optionLabel='text'
                            optionValue='value'
                            dataKey='value'
                            placeholder='Selecione'
                            @change='isValidFormPag = true'
                        )
                        .feedback--errors(v-if='!isValidFormPag')
                            .form-message--error(v-if='!isValidFormPag') Selecione uma <b>Forma de Pagamento</b>.
                    
                    .p-col-12.p-md-5(:class="{ 'form-group--error': !isValidBandeira }" style="margin-bottom:15px")
                        label.form-label Bandeira:
                        MultiSelect(v-model='taxaModel.cd_bandeiras' :options='options.cd_bandeiras'
                            optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione' filter @change='isValidBandeira = true')
                        .feedback--errors(v-if='!isValidBandeira')
                            .form-message--error(v-if='!isValidBandeira') Selecione ao menos uma <b>Bandeira</b>.
                    
                    .p-col-12.p-md-2(:class="{ 'form-group--error': !isValidPrevBaixa }" style="margin-bottom:15px") 
                        label.form-label Previsão de Baixa:
                        InputNumber(  :useGrouping="false" v-model="taxaModel.nr_dias_pagamento" mode="decimal" :min="0" :max="32" @input='isValidPrevBaixa = true')
                        .feedback--errors(v-if='!isValidPrevBaixa')
                            .form-message--error(v-if='!isValidPrevBaixa') Indique uma <b>Previsão de Baixa</b>.

                    .p-col-12.p-md-12(v-for="(n_input, index) in itemTaxaList" :key="index" style="margin-top:5px",)
                        .p-grid.p-fluid.p-align-end
                            .p-col-12.p-md-1
                                label.form-label Parcela Início:
                                InputText(:id="'inicio' + n_input" :disabled='true' v-model='n_input.nr_parcela_inicio' type='number')

                            .p-col-12.p-md-1(:class="{ 'form-group--error': !isValidItemParcela && itemTaxaList.length == index + 1 }")
                                label.form-label Parcela Fim:
                                InputNumber(:id="'fim' + n_input"  :useGrouping="false" 
                                v-model="n_input.nr_parcela_fim" mode="decimal" @blur='setFimParcela(index)')
                                .feedback--errors(v-if='!isValidItemParcela')

                            .p-col-12.p-md-2
                                label.form-label Taxa Valor Real:
                                .p-inputgroup
                                    span.p-inputgroup-addon R$
                                    span
                                        currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                            :id="'valor_real' + n_input"
                                            v-model='n_input.nr_taxa_valor_real'
                                            locale='pt-BR'
                                            :auto-decimal-mode='false'
                                            :value-range='{ min: 0 }')
                            
                            .p-col-12.p-md-2
                                label.form-label Taxa Porcentagem:
                                .p-inputgroup
                                    span.p-inputgroup-addon %
                                    span
                                        currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                            :id="'porcentagem' + n_input"
                                            v-model='n_input.nr_taxa_porcentagem'
                                            locale='pt-BR'
                                            :auto-decimal-mode='false'
                                            :value-range='{ min: 0, max: 100 }')

                            .p-col-12.p-md-2(:class="{ 'form-group--error': !isValidItemTime && itemTaxaList.length == index + 1 }")
                                label.form-label Data Inicial de Vigência:
                                InputMask(type='tel' :id="'p_inicio' + n_input" v-model='n_input.dt_inicio_vigencia' mask='99/99/9999' placeholder='dd/mm/aaaa' @change='isValidItemTime = true')

                            .p-col-12.p-md-2(:class="{ 'form-group--error': !isValidItemTime && itemTaxaList.length == index + 1}")
                                label.form-label Data Final de Vigência:
                                InputMask(type='tel' :id="'p_fim' + n_input" v-model='n_input.dt_fim_vigencia' mask='99/99/9999' placeholder='dd/mm/aaaa' @change='isValidItemTime = true')
                            
                            .p-col-12.p-md-1
                                label.form-label Cobrar Cliente:
                                InputSwitch(:id="'cobrar' + n_input" v-model='n_input.ie_cobra_cliente')
                            
                            .p-col-12.p-md-1.btn-remove-item
                                Button(
                                    type="button",
                                    icon="jam jam-trash",
                                    class="p-button-danger"
                                    style="width: 30px; margin-right:5px",
                                    @click="removeParcela(index)"
                                    v-if="itemTaxaList.length > 1"
                                )
                                Button(
                                    type="button",
                                    icon="jam jam-plus",
                                    style="width: 30px",
                                    @click="addParcela(n_input, index)"
                                    v-if="itemTaxaList.length === (index+1) && !itemTaxaList.some(item => item.nr_parcela_fim === 12)"
                                )

                    .p-col-12.p-md-12.ta-center(style="margin-bottom:20px; margin-top:15px;")
                        Button.addButton(label=`${ isEdit ? 'Editar taxa' : 'Adicionar taxa' }` type='button' icon='jam jam-plus' @click='adicionarTaxa()')

        ProgressBar(v-show='waiting' mode="indeterminate")
        div(v-show='!waiting')
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' type='button' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='"operadora" '>Operadoras</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-show='waitingForm' mode="indeterminate")
                div(v-show='!waitingForm')
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } operadora`")
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.ds_operadora.$error }")
                                label.form-label Descrição:
                                InputText(type='text' v-model='model.ds_operadora')
                                .feedback--errors(v-if='submitted && $v.model.ds_operadora.$error')
                                    .form-message--error(v-if="!$v.model.ds_operadora.minLength") <b>Nome</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.ds_operadora.required") <b>Descrição</b> é obrigatória.


                            .p-col-12.p-md-3
                                label.form-label Gateway:
                                Dropdown(
                                    v-model='model.ie_gateway'
                                    :options='filtersOptions.gateways'
                                    optionLabel='text'
                                    optionValue='value'
                                    dataKey='value'
                                    placeholder='Selecione'
                                        @change='applyFilters()'
                                )

                            //- .p-col-12.p-md-10(:class="{ 'form-group--error': submitted && $v.model.unidades.$error }")
                            //-     label.form-label Postos:
                            //-     MultiSelect.multiPostos(v-model='model.unidades' :options='options.unidades'
                            //-         optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione' filter)
                            //-     .feedback--errors(v-if='submitted && $v.model.unidades.$error')
                            //-         .form-message--error(v-if="!$v.model.unidades.required") Selecione ao menos um <b>Posto</b>.

                            .p-col-12.p-md-2()
                                label.form-label Taxa de Antecipação:
                                .p-inputgroup
                                    span.p-inputgroup-addon %
                                    span
                                        currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                            v-model='model.nr_taxa_antecipacao'
                                            locale='pt-BR'
                                            :auto-decimal-mode='false'
                                            :value-range='{ min: 0, max: 100 }')
                                

                            
                            .p-col-12.p-md-1
                                label.form-label Cobrar Cliente:
                                InputSwitch(v-model='model.ie_cobrar_cliente')
                            .p-col-12.p-md-12
                                label.form-label Observação:
                                TextArea(type='text' v-model='model.ds_observacao' rows="2" :autoResize="false" style='width: 100%' )



                            .p-col-12.p-md-12
                                Panel.panelParcelas(header="Parcelas e taxas")
                                    .p-grid.p-fluid.p-align-end
                                        .p-col-12.p-md-12
                                            Panel(header="Filtros")
                                                .p-grid.p-fluid.p-align-end
                                                    .p-col-12.p-md-3
                                                        label.form-label Formas de Pagamento:
                                                        MultiSelect( v-model='filters.cd_formas_pagamento' :options='filtersOptions.formasPagamento'
                                                                    dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' @change='applyFilters()')
                                                    
                                                    .p-col-12.p-md-3
                                                        label.form-label Bandeira:
                                                        MultiSelect( v-model='filters.cd_bandeiras' :options='filtersOptions.cd_bandeiras'
                                                                    dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' 
                                                                    :filter='filtersOptions.cd_bandeiras > 0'  @change='applyFilters()')

                                                    .p-col-12.p-md-2
                                                        label.form-label Parcela de Início:
                                                        InputText(v-model='filters.nr_parcela_inicio' type='number'  @change='applyFilters()')

                                                    .p-col-12.p-md-2
                                                        label.form-label Parcela de Fim:
                                                        InputText(v-model='filters.nr_parcela_fim' type='number' @change='applyFilters()')

                                                    // .p-col-12.p-md-2
                                                    //     label.form-label Cobrar Cliente:
                                                    //     SelectButton(v-model='filters.ie_cobrar' :options='filtersOptions.cobrar' optionLabel='text' 
                                                    //     optionValue='value' @change='applyFilters()')
                                                    
                                                    .p-col-12.p-md-2
                                                        label.form-label Cobrar Cliente:
                                                        Dropdown(
                                                            v-model='filters.ie_cobrar'
                                                            :options='filtersOptions.ie_cobrar'
                                                            optionLabel='text'
                                                            optionValue='value'
                                                            dataKey='value'
                                                            placeholder='Selecione'
                                                             @change='applyFilters()'
                                                        )

                                                    .p-col-12.p-md-12.ta-right
                                                        Button.addButton(label='Limpar filtros' type='button' icon='jam jam-rubber' @click='clearFilters()')
                                        
                                        .p-col-12.p-md-12
                                            div(v-if='!list.length')
                                                p.ta-center.text-secondary(style='margin-top: 20px;') <b> Sem taxas cadastradas. </b>
                                            div(v-else)
                                                DataTable(:value="list") 
                                                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center;' field='cd_forma_pagamento' header='Forma de Pagamento')
                                                        template(#body='props')
                                                            span <b> {{ filtersOptions.formasPagamento.find(item => item.value == props.data.cd_forma_pagamento).text }} </b>
                                                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='cd_bandeiras' header='Bandeira(s)')  
                                                        template(#body='props')   
                                                            div(v-for='bandeira, idx in props.data.cd_bandeiras' :key='idx')
                                                                li <b> {{options.cd_bandeiras.length ? options.cd_bandeiras.find(item => item.value == bandeira).text : '' }} </b>
                                                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center;' field='parcelas' header='Parcelas')
                                                        template(#body='props')   
                                                            div(v-for='parcela, idx in props.data.parcelas' :key='idx')
                                                                li <b> {{ parcela.nr_parcela_inicio }} a {{ parcela.nr_parcela_fim }}</b>
                                                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center;' field='nr_taxa_valor_real' header='Taxa Valor Real') 
                                                        template(#body='props')   
                                                            div(v-for='parcela, idx in props.data.parcelas' :key='idx')
                                                                li <b> {{ $root.formatPrice(parcela.nr_taxa_valor_real) }}</b>
                                                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center;' field='nr_taxa_porcentagem ' header='Taxa Porcentagem')
                                                        template(#body='props')
                                                            div(v-for='parcela, idx in props.data.parcelas' :key='idx')
                                                                li <b> {{ parcela.nr_taxa_porcentagem }} %</b>
                                                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='dt_inicio_vigencia' header='Vigência')  
                                                        template(#body='props')
                                                            div(v-for='parcela, idx in props.data.parcelas' :key='idx')
                                                                li <b> {{ parcela.dt_inicio_vigencia }} a {{ parcela.dt_fim_vigencia }}</b>
                                                    Column(headerStyle='width: 6%;' header='Ações')
                                                        template(#body='props')
                                                            .ta-center
                                                                Button.p-button-raised.p-button-rounded.mr-1(
                                                                    type='button'
                                                                    v-tooltip.top="'Editar'"
                                                                    icon="jam jam-write"
                                                                    @click="editar(props.data.index)"
                                                                )
                                                                Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                                                    type='button'
                                                                    v-tooltip.top="'Excluir'"
                                                                    icon="jam jam-minus-circle"
                                                                    @click="dialogApagar_data=props.data;dialogApagar=true"
                                                                )
                                        .p-col-12.p-md-12.ta-center
                                            Button.addButton(label='Adicionar taxa' type='button' icon='jam jam-plus' @click='dialogAdicionar = true')
                    
                            .p-md-10
                            .p-col-12.p-md-2
                                Button(label='Salvar' icon='jam jam-save' type="submit")

</template>

<style lang="scss">
    .operadora-salvar {
        .datatable {
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
                &.l  { background-color: #e1f0f8; }
                &.r  { background-color: #f1e1f8; }
            }
        }
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled], .p-inputtext[readonly=readonly] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .waitingCEP {
            position: absolute;
            top: 34px;
            right: 20px;
            .p-progress-spinner {
                width: 20px;
                height: auto;
            }
        }
        .link-naoseimeucep {
            position: absolute;
            right: 8px;
            bottom: -14px;
            font-size: 13px;
            font-weight: 700;
        }
        .dialogAdicionar {
            width: 96%;
            max-width: 900px;
            min-height:500px;
            .p-dropdown.p-disabled .p-inputtext {
                color: #000;
                font-weight: 600;
                background-color: #ddd;
            }
        }
        .datatable-cep {
            td { font-size: 13px; }
            max-height: 200px;
            overflow-y: auto;
        }
        .waitingCEPDialog, .waitingCarteira {
            width: 25px;
            height: auto;
        }
        .text-obs-responsavel {
            color: #888;
            font-size: 12px;
        }
        .dropdown-buscaResponsavel {
            .p-dropdown-label {
                font-weight: 700 !important;
            }
        }
        .addButton {
            max-width: 200px;
        }
        .panelParcelas {
            display: block;
            justify-content: center;
        }
        .btn-remove-item{
            align-items: flex-end;
            justify-content: flex-start;
            display: flex;
            margin-top: 20px;
        }.multiPostos{
             .p-multiselect-filter-container {
                width: 95% !important;
            }
        }
        .p-multiselect-filter-container {
            width: 85% !important;
        }
        .dialogApagar {
        width: 350px;
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import TabView from 'primevue/tabview'
    import TabPanel from 'primevue/tabpanel'
    import Textarea from 'primevue/textarea'
    import Checkbox from 'primevue/checkbox'
    import InputSwitch from 'primevue/inputswitch';
    import MultiSelect from 'primevue/multiselect'
    import InputNumber from 'primevue/inputnumber';
    

    import { DominioValor, UnidadesMedclub, Operadora } from './../../middleware'
    import { required, minLength } from 'vuelidate/lib/validators'
    import moment from 'moment'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, DataTable, 
            TabView, InputSwitch, TabPanel, InputMask, SelectButton, InputNumber,
            Dropdown, Tooltip, ProgressSpinner, Column, Textarea, Checkbox, MultiSelect
        },
        directives: { tooltip: Tooltip },
        watch: {
            'model.cd_pai': function (val) {
                if(val){
                    this.model.ie_permite_indicacao = false
                }
            },
            
        },
        created () {
            UnidadesMedclub.findAll().then(response => {
                if(([200, 201]).includes(response.status)){
                    this.options.unidades = response.data.map(item => ({ value: item.id, text: item.nm_unidade}));
                } 
            })
            DominioValor.findAll({ds_mnemonico:['BANDEIRA_CREDITO', 'FORMA_PAGAMENTO_OPERADORA', 'GATEWAY']}).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.filtersOptions.formasPagamento = response.data["FORMA_PAGAMENTO_OPERADORA"].valores.map(item => ({value: item.ie_valor, text: item.ds_valor}))
                        this.options.cd_bandeiras = response.data["BANDEIRA_CREDITO"].valores.map(item => ({ value: item.ie_valor, text: item.ds_valor }));
                        this.filtersOptions.gateways = response.data["GATEWAY"].valores.map(item => ({ value: item.ie_valor, text: item.ds_valor }));
                        this.filtersOptions.cd_bandeiras = this.options.cd_bandeiras;
                    }
                    this.waiting_ie_situacao = false
                })
            let id = 0;
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id);
            if (id != 0){
                Operadora.find(id).then(response => {
                    if(([200, 201]).includes(response.status)){
                        this.model = {
                            ...response.data,
                            nr_taxa_antecipacao: +response.data.nr_taxa_antecipacao
                            };
                        this.auxList = this.list = response.data.taxas.map((item, idx) => ({
                                ...item,
                                index: idx + 1, 
                                parcelas: item.parcelas.map((i, index) => ({
                                    ...i,
                                    index: index + 1, 
                                    dt_inicio_vigencia: moment(i.dt_inicio_vigencia, "YYYY-MM-DD").format("DD/MM/YYYY"),
                                    dt_fim_vigencia: moment(i.dt_fim_vigencia, "YYYY-MM-DD").format("DD/MM/YYYY")
                                }))
                                
                            }));              
                    }
                })
            }
            this.waiting = false;
        },
        data () {
            return {
                list: [],
                auxList: [],
                itemTaxaList: [{
                    id: 0,
                    nr_parcela_inicio: 1,
                    nr_parcela_fim: null,
                    nr_taxa_valor_real: 0,
                    nr_taxa_porcentagem: 0,
                    dt_inicio_vigencia: null,
                    dt_fim_vigencia: null,
                    ie_cobra_cliente: false
                }],
                model: {
                    id: null,
                    unidades: [],
                    ds_operadora: '',
                    ds_observacao: null,
                    ie_principal: false,
                    nr_taxa_antecipacao: 0,
                    ie_cobrar_cliente: true,
                    ie_gateway: null
                },
                taxaModel: {
                    cd_forma_pagamento: null,
                    cd_bandeiras: [],
                    nr_dias_pagamento: null,
                    id: null
                },
                
                parcelasModel: {
                    nr_parcela_inicio: null,
                    nr_parcela_fim: null,
                    nr_taxa_valor_real: null,
                    nr_taxa_porcentagem: 0,
                    dt_inicio_vigencia: null,
                    dt_fim_vigencia: null,
                    ie_cobra_cliente: false
                },
                filters: {
                    ie_status: true,
                    cd_bandeiras:'',
                    cd_formas_pagamento:'',
                    nr_parcela_inicio: '',
                    nr_parcela_fim: '',
                    ie_cobrar: null,
                    cd_unidades: [],
                    
                },
                filtersOptions: {
                    ie_cobrar: [
                        { text: 'TODOS', value: null },
                        { text: 'Sim', value: true },
                        { text: 'Não', value: false }
                    ],
                    formasPagamento: [],
                    bandeiras: [],
                    gateways: [],
                    ieStatus: [
                        { text: "TODOS", value: null },
                        { text: "Ativo", value: true },
                        { text: "Inativo", value: false },
                    ],
                    cobrar: [
                        { text: "Sim", value: true },
                        { text: "Não", value: false }
                    ]
                },
                options: {
                    unidades: [],
                    cd_bandeiras: []
                },
                buscaResponsavel: {
                    field: 'nm_pessoa_fisica',
                    value: null,
                    result: []
                },
                agendamentos: [],
                agendamentosTabs: [
                    { header: 'Consultas', ie_tipo_agenda: 'C' },
                    { header: 'Exames', ie_tipo_agenda: 'E' }
                ],
                dialogApagar: false,
                dialogApagar_data: null,
                dialogAdicionar: false,
                isEdit: false,
                waiting: true,
                waitingForm: false,
                isValidItemTime: true,
                isValidItemParcela: true,
                isValidFormPag: true,
                isValidBandeira: true,
                isValidPrevBaixa: true,
                submitted: false,
                isValidDs: {
                    minLength: false,
                    required: false
                },
                isValidObs: {
                    required: false
                }
            }
        },
        validations () {
            let v = {
                model: {
                    ds_operadora: { required, minLength: minLength(2) },
                    unidades: { required }
                }
            }
            return v
        },
        methods: {
            handleSubmit(){
                this.submitted = true;
                // if(this.model.ds_operadora || this.model.ds_operadora == ''){
                //    this.isValidDs = false;
                //    return 0;
                // }
                // if(!this.model.unidades.length || this.mode.unidades.length == 0){
                //     this.isValidObs = false;
                // }
                // this.$v.$touch()
                // if (this.$v.$invalid) return 0
                // else if(this.auxList.length == 0){
                //     this.$toast.error("Insira ao menos um item na lista de taxas", { duration: 3000 })
                // }else{
                    let dataSend = {
                        ...this.model,
                        taxas: this.auxList.map(item => ({
                            ds_sigla: this.model.ds_operadora,
                            cd_forma_pagamento: item.cd_forma_pagamento,
                            cd_bandeiras: item.cd_bandeiras,
                            nr_dias_pagamento: +item.nr_dias_pagamento,
                            parcelas: item.parcelas.map(i => ({
                                nr_parcela_inicio: i.nr_parcela_inicio,
                                nr_parcela_fim: i.nr_parcela_fim,
                                nr_taxa_valor_real: i.nr_taxa_valor_real,
                                nr_taxa_porcentagem: i.nr_taxa_porcentagem,
                                dt_inicio_vigencia: moment(i.dt_inicio_vigencia, "DD/MM/YYYY").format("YYYY-MM-DD"),
                                dt_fim_vigencia: moment(i.dt_fim_vigencia, "DD/MM/YYYY").format("YYYY-MM-DD"),
                                ie_cobra_cliente: i.ie_cobra_cliente
                            }))
                        }))
                    }
                    Operadora.save(dataSend).then(response => {
                        if(([200, 201]).includes(response.status)){
                            this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                            this.$router.push(`/operadora/`);
                        }else{
                            this.$toast.error("Erro no Cadastro", { duration: 3000 })
                        }
                    })
                // }
            },
            applyFilters(){
                let newList = [];
                this.auxList.forEach(item => {
                    let valido = true;
                    if(this.filters.ie_cobrar && !item.parcelas.some(i => i.ie_cobra_cliente == this.filters.ie_cobrar)) valido = false;
                    if(this.filters.cd_formas_pagamento.length && !this.filters.cd_formas_pagamento.map(i => (parseInt(i))).includes(item.cd_forma_pagamento))  valido = false;
                    if(this.filters.cd_bandeiras.length && !this.filters.cd_bandeiras.filter(i => item.cd_bandeiras.some(j => i == j)).length > 0) valido = false;
                    if(this.filters.nr_parcela_inicio != '' && !item.parcelas.some(i => +i.nr_parcela_inicio == +this.filters.nr_parcela_inicio)) valido = false;
                    if(this.filters.nr_parcela_fim != '' && !item.parcelas.some(i => +i.nr_parcela_fim == +this.filters.nr_parcela_fim)) valido = false;
                    if(valido) newList.push(item);
                 });
                 this.list = newList;
            },
            clearFilters() {
                this.list = this.auxList;
                 this.filters= {
                    ie_status: true,
                    cd_bandeiras:'',
                    cd_formas_pagamento:'',
                    nr_parcela_inicio: '',
                    nr_parcela_fim: '',
                    ie_cobrar: null,
                    cd_unidades: [],   
                }
            },
            clearTaxaModel() {
                this.taxaModel = {
                    cd_forma_pagamento: null,
                    cd_bandeiras: [],
                    nr_dias_pagamento: null,
                    parcelas: []
                }
            }, 
            clearParcelaModel(){
                this.parcelaModel= {
                    nr_parcela_inicio: null,
                    nr_parcela_fim: null,
                    nr_taxa_valor_real: null,
                    nr_taxa_porcentagem: 0,
                    dt_inicio_vigencia: null,
                    dt_fim_vigencia: null,
                    ie_cobra_cliente: false
                }
            },
            addParcela(item, index){
                this.validItemTaxa(item, true);
                if(+item.nr_parcela_fim <= 12 && this.isValidItemTime && this.isValidItemParcela)
                    this.itemTaxaList.push({
                        nr_parcela_inicio: +item.nr_parcela_fim + 1,
                        nr_parcela_fim: +item.nr_parcela_fim + 1 == 12? 12 : null,
                        nr_taxa_valor_real: 0,
                        nr_taxa_porcentagem: 0,
                        dt_inicio_vigencia: null,
                        dt_fim_vigencia: null,
                        ie_cobra_cliente: false,
                        id: index + 1
                    });
            },
            removeParcela(index){
                if(index > 0) {
                    this.$delete(this.itemTaxaList,index)
                } else {
                    if(this.itemTaxaList.length > 1)
                        this.$delete(this.itemTaxaList,index)
                    else {
                        this.restaurarTaxasList()
                    }
                }
            },
            editTaxa(index) {
                this.taxaModel = this.auxList[index];
                this.itemTaxaList = this.auxList[index].parcelas;
            },
            adicionarTaxa(){
                if(!this.taxaModel.cd_forma_pagamento) this.isValidFormPag = false;
                if(!this.taxaModel.cd_bandeiras.length || this.taxaModel.cd_bandeiras.length == 0) this.isValidBandeira = false;
                if(!this.taxaModel.nr_dias_pagamento) this.isValidPrevBaixa = false;
                // if(this.itemTaxaList.length == 1) this.validItemTaxa(this.itemTaxaList[0]);
                this.validItemTaxa(this.itemTaxaList[this.itemTaxaList.length - 1])
                this.validTaxaTotal();
                if(this.isValidFormPag && this.isValidBandeira && this.isValidPrevBaixa && this.isValidItemTime && this.isValidItemParcela){
                    if(this.isEdit){
                        this.auxList[this.isEdit - 1] = {
                            cd_forma_pagamento: +this.taxaModel.cd_forma_pagamento,
                            cd_bandeiras: this.taxaModel.cd_bandeiras,
                            nr_dias_pagamento: this.taxaModel.nr_dias_pagamento,
                            parcelas: this.itemTaxaList,
                            id: this.taxaModel.id,
                            index: this.auxList[this.isEdit -1].index
                        }
                        this.isEdit = false;
                    }else {
                        this.auxList.push({
                            cd_forma_pagamento: +this.taxaModel.cd_forma_pagamento,
                            cd_bandeiras: this.taxaModel.cd_bandeiras,
                            nr_dias_pagamento: this.taxaModel.nr_dias_pagamento,
                            parcelas: this.itemTaxaList,
                            id: this.taxaModel.id,
                            index: this.auxList.length + 1
                        });

                    }
                    this.list = this.auxList;
                    this.restaurarTaxasList()
                    this.dialogAdicionar = false;
                }
            },
            restaurarTaxasList(){
                this.itemTaxaList = [{
                nr_parcela_inicio: 1,
                nr_parcela_fim: null,
                nr_taxa_valor_real: 0,
                nr_taxa_porcentagem: 0,
                dt_inicio_vigencia: null,
                dt_fim_vigencia: null,
                ie_cobra_cliente: false,
                id: 0
            }];
            this.isValidItemParcela = true;
            this.isValidItemTime = true;
            this.isValidFormPag = true;
            this.isValidBandeira = true;
            this.isValidPrevBaixa = true;
            this.isEdit = false;
            },
            excluir(idx) {
                this.auxList.splice(idx - 1, 1);
                this.list = this.auxList;
                this.dialogApagar = false;
            },
            editar(idx){
                const element = this.auxList[idx - 1];
                this.taxaModel = {
                    cd_forma_pagamento: element.cd_forma_pagamento,
                    cd_bandeiras: element.cd_bandeiras,
                    nr_dias_pagamento: element.nr_dias_pagamento,
                    id: element.id
                },
                this.itemTaxaList = element.parcelas.map(item => ({
                    ...item,
                    nr_taxa_valor_real: +item.nr_taxa_valor_real,
                    nr_taxa_porcentagem: +item.nr_taxa_porcentagem
                }));
                this.dialogAdicionar = true;
                this.isEdit = idx;
            },
            validItemTaxa (item, isAdd = false) {
                this.isValidItemTime = true;
                this.isValidItemParcela = true;
                const fim = moment(item.dt_fim_vigencia, 'DD/MM/YYYY');
                const inicio = moment(item.dt_inicio_vigencia, 'DD/MM/YYYY');
                if(isAdd && moment().isAfter(inicio, 'day')) {
                    this.isValidItemTime = false;
                    this.$toast.error("Data de cadastro não pode ser posterior a data atual", { duration: 4000 })
                }
                if(!item.dt_fim_vigencia || !item.dt_inicio_vigencia) {
                    this.isValidItemTime = false;
                    this.$toast.error("Insira data de início e fim de vigência", { duration: 4000 })
                }
                else if(moment(fim).isBefore(inicio, 'day')) {
                    this.isValidItemTime = false;
                    this.$toast.error("Data de fim não pode ser anterior a data de início", { duration: 4000 })
                }
                if(!item.nr_parcela_fim) {
                    this.isValidItemParcela = false;
                    this.$toast.error("Insira um valor de parcela final", { duration: 4000 })
                }
                
            },
            setFimParcela (index) {
                if(this.itemTaxaList[index].nr_parcela_fim && this.itemTaxaList[index].nr_parcela_fim < this.itemTaxaList[index].nr_parcela_inicio) this.itemTaxaList[index].nr_parcela_fim = this.itemTaxaList[index].nr_parcela_inicio
                else if(this.itemTaxaList[index].nr_parcela_fim  > 12) this.itemTaxaList[index].nr_parcela_fim = 12;
                this.isValidItemParcela=true;
            },
            validTaxaTotal(){
                this.auxList.forEach(item => {
                    if(this.taxaModel.cd_forma_pagamento == item.cd_forma_pagamento){
                        if(this.taxaModel.cd_bandeiras.find(i => item.cd_bandeiras.some(j => i == j))){
                            item.parcelas.forEach(i => {
                                this.itemTaxaList.forEach(j => {
                                    const inicio = moment(i.dt_inicio_vigencia, 'DD/MM/YYYY');
                                    const fim = moment(i.dt_fim_vigencia, 'DD/MM/YYYY');
                                    const modelInicio = moment(j.dt_inicio_vigencia, 'DD/MM/YYYY');
                                    const modelFim = moment(j.dt_fim_vigencia, 'DD/MM/YYYY');
                                    if(moment(modelInicio).isBefore(inicio, "day") &&
                                    !moment(modelFim).isBefore(inicio, "day")){
                                        this.isValidItemTime = false;   
                                        this.$toast.error("Há conflitos de vigência com taxas ja adicionadas", { duration: 4000 })
                                    }
                                    if(moment(modelInicio).isAfter(inicio, "day") &&
                                    !moment(modelInicio).isAfter(fim, "day")){
                                        this.isValidItemTime = false;   
                                        this.$toast.error("Há conflitos de vigência com taxas ja adicionadas", { duration: 4000 })
                                    }
                                })
                            })
                        }
                    }
                })
            }
        }
    }
</script>
